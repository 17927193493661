<nav class="sidebar-nav w-100 top-[50px] lg:w-[135px] lg:pl-[5px] lg:top-[82px]">
    <ul class="nav flex-column">
        <div>
            <li class="lg:hidden">
                <div class="name">
                    <small>Welcome,</small>
                    <br>
                    {{ displayName }}
                </div>
            </li>
            <br class="lg:hidden"/>
            <app-investor-dropdown class="lg:hidden" [updateWidth]="showMobileSideNav"></app-investor-dropdown>
            <br class="lg:hidden"/>
            <ng-container *ngFor="let nav of navigationItems">
                <li class="nav-item pointer" *ngIf="nav.openDocumentLinkIn">
                    <a href="{{nav.url}}" (click)="navLinkClick(nav)" [class.active]="activelink.includes(nav.url)">
                        <div class="img-wrap">
                            <img src="{{nav.activeStateImage.url}}" class="active-img" />
                            <img src="{{nav.inactiveStateImage.url}}" class="normal-img" />
                        </div>
                        <div *ngFor="let displayText of nav.displayText">{{displayText}}</div>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngFor="let linkItem of navigationItems">
                <li class="nav-item pointer" *ngIf="!linkItem.openDocumentLinkIn">
                    <a href="{{linkItem.url}}" target="_blank" (click)="navLinkClick(linkItem)">
                        <div class="img-wrap">
                            <img src="{{linkItem.inactiveStateImage.url}}" class="normal-img pointer" />
                        </div>
                        <div class="row ml-[19px] mr-0 external-redirect">
                            <div>
                                <div *ngFor="let displayText of linkItem.displayText">{{displayText}}
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </ng-container>
            <br/>
        </div>
        <app-logout-button class="self-center fixed bottom-[2rem] lg:hidden"></app-logout-button>
    </ul>
</nav>